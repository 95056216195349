import http from '../http-common'
import { APIBaseFunctions, APIDataSliceObj, defaultAPILimit } from '@/components/Utility/APIBase'

export class Draft extends APIBaseFunctions {
  static DraftMatchScoreDataService = class {
    private static draftMatchScorDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static async getDraftMatchScore (extraParameter = '') {
      return http.get('/kamp-saet-udkasts' + `?${extraParameter}`)
    }

    static updateDraftMatchScore (id: string, data: any) {
      return http.put(`/kamp-saet-udkasts/${id}`, data)
    }
  }
}

export default new Draft()
