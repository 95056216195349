import { PropType } from 'vue'
import { Options, Vue } from 'vue-class-component'
import { Watch, Emit, Model } from 'vue-property-decorator'
import { useRoute } from 'vue-router'
import { Tournament501MatchSetRounds } from '@/components/Utility/tournament501MatchSetRounds'
import { Draft } from '@/services/MatchSeatsDraftService'
import { defaultJuniorTournamentSpringId, defaultJuniorTournamentFallId } from '@/components/Utility/Common'
// import BaseFunctions from '@/components/Utility/Base'

/* export default defineComponent({
  name: 'TournamentAdvancedMatchSetRoundsMode',
  setup () {
    // component setup
  }
}) */

type roundsModeDataType = { status: number; readOnlyMode: boolean; playerStartingFirstRound: boolean; currentPlayerWhoseTurnItIs: boolean; submatchWinAtNumberOfWonSets: number; matchIndexNumber: number; matchSet: number[]; }
type dataReturnType = { roundsModeData: roundsModeDataType; advancedMatchSetModeModal: boolean; dart43Modal: boolean; matchSetRounds: Tournament501MatchSetRounds | undefined; index: number; error: any; }
type stateobjType = { showModal: boolean; status: number; matchIndexNumber: number; matchSet: number[]; winningPlayer: boolean | null; winningPlayerDartValue: number; winningPlayerCloseValue: number; remainderPointsOfLoser: number; match180ScoreAmount: number[]; playerWhoStartedFirstRound: boolean; }

@Options({
  props: {
    msg: String,
    readOnlyFlag: Boolean,
    playerToStartFirstRound: Boolean,
    submatchWinAtNumberOfWonSets: Number,
    matchIndexNumber: Number,
    matchSet: Array as PropType<number[]>,
    matchId: Number,
    selectedMatchId: Number,
    playerTeamNames: Array as PropType<string[]>,
    matchTeamPlayersNames: Array as PropType<string[]>,
    matchTeamSecondPlayersNames: Array as PropType<string[]>,
    homeTeamResult: Number,
    awayTeamResult: Number,
    matchIsDouble: Boolean,
    tournamentCategoryId: Number
  },
  components: {}
})
export default class TournamentAdvancedMatchSetRoundsMode extends Vue {
  msg!: string
  readOnlyFlag!: boolean
  playerToStartFirstRound!: boolean
  submatchWinAtNumberOfWonSets!: number
  matchIndexNumber!: number
  matchSet!: number[]
  matchId!: number
  selectedMatchId!: number
  playerTeamNames!: string[]
  matchTeamPlayersNames!: string[]
  matchTeamSecondPlayersNames!: string[]
  matchIsDouble!: boolean
  homeTeamResult!: number
  awayTeamResult!: number
  tournamentCategoryId!: number
  error: any = null
  private roundsModeData: roundsModeDataType = { status: 0, readOnlyMode: false, playerStartingFirstRound: true, currentPlayerWhoseTurnItIs: true, submatchWinAtNumberOfWonSets: 3, matchIndexNumber: 0, matchSet: [0, 0] }
  private matchSetRounds: Tournament501MatchSetRounds | undefined
  private playerScoreInputValue = ''
  private playersTotalMatchSetScoreForTheMatch = [0, 0]
  private playersNumberOfDartArrowsTrackingArray = [[0, 0]]
  private tempPlayerStartingFirstRound = true
  private closingNumberOfDartArrowsPossibleValue = '0'
  private closingNumberOfDartArrowsPossibleOptions = [{ text: 'Ingen pile', value: '0', disabled: false }]
  private resumeMatchSetValue = '0'
  private resumeMatchSetOptions = [{ text: 'Udeholdspilleren', value: '0', disabled: false }, { text: 'Hjemmeholdspilleren', value: '1', disabled: false }]
  private labelCurrentPlayerWhoseTurnItIs = ''
  private scoreBoardHeadText = ['Ude', 'Hjem']
  private labelPlayerTeamText = ['Ude', 'Hjemme']
  private winningPlayer: boolean | null = null
  private textPlayerWon = ['Spilleren fra udeholdet har vundet', 'Spilleren fra hjemmeholdet har vundet']
  private playerWhoWonValue = '0'
  private playerWhoWonOptions = [{ text: 'Udeholdspilleren', value: '0' }, { text: 'Hjemmeholdspilleren', value: '1' }]
  public advancedMatchSetModeModal = false
  private finalMatchSetRoundsClosingDartsModal = false
  private inhibitNextTurn = false
  private dart43Flag = false
  public dart43Modal = false
  public resetWarningModal = false
  public resumeMatchSetModal = false
  private index = 0
  private isEditScore = false
  private selectedRemainingIndex = -1
  private isEditRemainingScore = false
  private scoreDataForEdit = { score: Number(this.playerScoreInputValue), turnIndex: 0, playerTurn: 0 }
  private isRoundScoreUpdate = false
  readonly name : string = 'TournamentAdvancedMatchSetRoundsMode'
  private route = useRoute()

  data (): dataReturnType {
    return {
      roundsModeData: this.roundsModeData,
      advancedMatchSetModeModal: this.advancedMatchSetModeModal,
      dart43Modal: this.dart43Modal,
      matchSetRounds: this.matchSetRounds,
      index: this.index,
      error: this.error
    }
  }

  @Model('adv-match-set-rounds-mode-state-changed', { type: Object as PropType<stateobjType>, default: () => ({}) }) readonly stateobj!: stateobjType

  // Ignore the `on-` and use kebab-case.
  @Emit('adv-match-set-rounds-mode-state-changed')
  changeAdvMatchSetRoundsModeState (showModal: boolean, status: number) : stateobjType {
    let closingValueArr: number[] | null = [0, 0]
    let match180ScoreAmountArr: number[] | null = [0, 0]

    if (this.matchSetRounds !== undefined) {
      closingValueArr = this.matchSetRounds.getClosingRoundsResults()
      match180ScoreAmountArr = this.matchSetRounds.getTotalMatch180ScoreAmount()
    }
    if (closingValueArr === null) {
      closingValueArr = [0, 0]
    }
    if (match180ScoreAmountArr === null) {
      match180ScoreAmountArr = [0, 0]
    }
    if (this.dart43Flag) {
      return { showModal: showModal, status: status, matchIndexNumber: this.roundsModeData.matchIndexNumber, matchSet: this.roundsModeData.matchSet, winningPlayer: this.winningPlayer, winningPlayerDartValue: 43, winningPlayerCloseValue: 50, remainderPointsOfLoser: 50, match180ScoreAmount: match180ScoreAmountArr, playerWhoStartedFirstRound: this.tempPlayerStartingFirstRound }
    }

    // console.log('[changeAdvMatchSetRoundsModeState()] match180ScoreAmountArr = ' + JSON.stringify(match180ScoreAmountArr))
    return { showModal: showModal, status: status, matchIndexNumber: this.roundsModeData.matchIndexNumber, matchSet: this.roundsModeData.matchSet, winningPlayer: this.winningPlayer, winningPlayerDartValue: this.getNumberOfDartArrowsThrown()[(this.winningPlayer ? 1 : 0)], winningPlayerCloseValue: Number(closingValueArr[(this.winningPlayer ? 1 : 0)]), remainderPointsOfLoser: this.getPlayersPointsRemaining()[(!this.winningPlayer ? 1 : 0)], match180ScoreAmount: match180ScoreAmountArr, playerWhoStartedFirstRound: this.tempPlayerStartingFirstRound }
  }

  public doDart43 () {
    this.dart43Modal = true
  }

  public saveDart43 () {
    this.roundsModeData.status = 2
    // this.winningPlayer = (this.playerWhoWonValue === '0' ? false : true)
    this.winningPlayer = !(this.playerWhoWonValue === '0')

    if (this.matchSetRounds !== undefined) {
      this.matchSetRounds.setNumberOfClosingArrowsUsed(1)
      this.playersNumberOfDartArrowsTrackingArray[this.matchSetRounds.getNumberOfRoundsPlayed()] = this.getNumberOfDartArrowsThrown()
    }

    this.dart43Flag = true
    this.dart43Modal = false

    this.approveMatchSetResultsAsFinal()
  }

  public deleteLast () : void {
    if (this.playerScoreInputValue.length > 0) {
      this.playerScoreInputValue = this.playerScoreInputValue.slice(0, -1)
    }
  }

  public addNumber (input: string) : void {
    this.playerScoreInputValue += input
  }

  public submitAndAdvanceNextTurn () : void {
    // console.log('[submitAndAdvanceNextTurn()] DONE. Score = ' + this.playerScoreInputValue.toString())

    if (this.matchSetRounds !== undefined && !this.inhibitNextTurn) {
      if (!this.matchSetRounds.checkForClosingScoreConditionOfCurrentPlayer(Number(this.playerScoreInputValue.toString())) || this.matchSetRounds.checkForAllowableClosingScore(Number(this.playerScoreInputValue.toString()))) {
        this.matchSetRounds.setCurrentRoundCurrentPlayerScore(Number(this.playerScoreInputValue))
        this.matchSetRounds.nextPlayerTurn()
        this.playerScoreInputValue = ''
        // this.playersNumberOfDartArrowsTrackingArray.push(this.getNumberOfDartArrowsThrown())
        // console.log('[submitAndAdvanceNextTurn()] rounds index = ' + this.matchSetRounds.getNumberOfRoundsPlayed().toString())
        this.playersNumberOfDartArrowsTrackingArray[this.matchSetRounds.getNumberOfRoundsPlayed()] = this.getNumberOfDartArrowsThrown()
        // console.log('[submitAndAdvanceNextTurn()] playersNumberOfDartArrowsTrackingArray = ' + JSON.stringify(this.playersNumberOfDartArrowsTrackingArray))
        this.retrieveAndShowScore()
        this.updateInputLabelWhoseTurnItIs()
        this.roundsModeData.status = (this.roundsModeData.status < 1 ? 1 : this.roundsModeData.status)

        // If the table overflow, scrolls the table to the end.
        // console.log(this.$refs.scoreTableView)
        // const scroll = this.$refs.scoreTableView as InstanceType<typeof HTMLTableElement>
        // scroll.scrollIntoView({ behavior: 'smooth', block: 'end' })
        const element = document.getElementById('lastRow') as InstanceType<typeof HTMLTableElement>
        element.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }
  }

  public setRemainingToEdit (value: any) : void {
    this.selectedRemainingIndex = value
    this.isEditRemainingScore = true
    // const selectedRemainingScore = this.getPlayersPointsRemaining()[value]
    this.playerScoreInputValue = String('')
  }

  public submitToEditRemainingScore () : void {
    const editValue = Number(this.playerScoreInputValue)
    if (editValue === 0) {
      if (this.matchSetRounds !== undefined && !this.inhibitNextTurn) {
        const lastValue = this.getPlayersPointsRemaining()[this.selectedRemainingIndex] - Number(this.playerScoreInputValue)
        const result = this.matchSetRounds.checkForAllowableClosingScoreBeforEdit(lastValue)
        if (result) {
          this.matchSetRounds.setRoundScoreAfterEditRemaining({ score: Number(lastValue), playerTurn: this.selectedRemainingIndex })
          this.matchSetRounds.nextPlayerTurn()
          this.playersNumberOfDartArrowsTrackingArray[this.matchSetRounds.getNumberOfRoundsPlayed()] = this.getNumberOfDartArrowsThrown()
          this.retrieveAndShowScore()
          this.updateInputLabelWhoseTurnItIs()
          this.roundsModeData.status = (this.roundsModeData.status < 1 ? 1 : this.roundsModeData.status)
        }
        this.isEditRemainingScore = false
        this.playerScoreInputValue = ''
      }
    } else if (editValue <= this.getPlayersPointsRemaining()[this.selectedRemainingIndex] && editValue !== 1) {
      const lastValue = this.getPlayersPointsRemainingExceptCurrentPlayer(this.selectedRemainingIndex)[this.selectedRemainingIndex] - Number(this.playerScoreInputValue)
      if (this.matchSetRounds !== undefined && !this.inhibitNextTurn) {
        this.matchSetRounds.setRoundScoreAfterEditRemaining({ score: Number(lastValue), playerTurn: this.selectedRemainingIndex })
        this.matchSetRounds.updateNextPlayerTurnOnEditRemainingScore(this.selectedRemainingIndex)
        this.playersNumberOfDartArrowsTrackingArray[this.matchSetRounds.getNumberOfRoundsPlayed()] = this.getNumberOfDartArrowsThrown()
        this.isEditRemainingScore = false
        this.playerScoreInputValue = ''
        this.retrieveAndShowScore()
        this.updateInputLabelWhoseTurnItIs()
        this.roundsModeData.status = (this.roundsModeData.status < 1 ? 1 : this.roundsModeData.status)
      }
    } else {
      this.isEditRemainingScore = false
      this.playerScoreInputValue = ''
    }
  }

  public setScoreToEdit (value: { score: number, turnIndex: number, playerTurn: number }) : void {
    (this as any).$refs.playerscoreinputref.focus()
    this.isEditScore = true
    this.playerScoreInputValue = String('')
    this.scoreDataForEdit = value
  }

  public submitToEditScore () : void {
    if (this.matchSetRounds !== undefined && !this.inhibitNextTurn) {
      const closingScoreCondition = this.matchSetRounds.checkForClosingScoreConditionOfCurrentPlayerAtEdit({ score: Number(this.playerScoreInputValue), playerTurn: this.scoreDataForEdit.playerTurn, turnIndex: this.scoreDataForEdit.turnIndex })
      if (!closingScoreCondition || this.checkForCloseScore(this.scoreDataForEdit.playerTurn)) {
        this.matchSetRounds.setCurrentRoundCurrentPlayerScoreToEdit({ score: Number(this.playerScoreInputValue), playerTurn: this.scoreDataForEdit.playerTurn, turnIndex: this.scoreDataForEdit.turnIndex })
        this.playerScoreInputValue = ''
        this.isEditScore = false
        this.retrieveAndShowScore()
        this.updateInputLabelWhoseTurnItIs()
        this.roundsModeData.status = (this.roundsModeData.status < 1 ? 1 : this.roundsModeData.status)
      }
    }
  }

  public checkForCloseScore (playerTurn : number) : boolean {
    if (this.matchSetRounds !== undefined && !this.inhibitNextTurn) {
      const closingValueArr = this.matchSetRounds.getClosingRoundsResultsAtEdit()
      const closingRoundIndex = this.matchSetRounds.getClosingRoundsPosition()
      let closingScoreValue = closingValueArr ? closingValueArr[playerTurn] : 0

      if (closingRoundIndex[playerTurn] === this.scoreDataForEdit.turnIndex) {
        closingScoreValue = Number(this.playerScoreInputValue)
      }

      console.log('closingScoreValue', closingScoreValue)
      const result = this.matchSetRounds.checkForAllowableClosingScoreBeforEdit(closingScoreValue)

      return result
    }

    return false
  }

  public clearScoreToEdit () : void {
    this.isEditScore = false
    this.isEditRemainingScore = false
    this.selectedRemainingIndex = -1
    this.playerScoreInputValue = ''
    this.scoreDataForEdit = { score: Number(this.playerScoreInputValue), turnIndex: 0, playerTurn: 0 }
  }

  public shortCut (input: string) : void {
    this.playerScoreInputValue = input
    this.submitAndAdvanceNextTurn()
  }

  public undoToPreviousTurn () : void {
    // console.log('[undoToPreviousTurn] DONE.')

    if (this.matchSetRounds !== undefined) {
      this.matchSetRounds.setCurrentRoundCurrentPlayerScore(0)
      // this.matchSetRounds.previousPlayerTurn()
      this.matchSetRounds.undoLatestRoundPlayerScoreEntry()
      this.matchSetRounds.setCurrentRoundCurrentPlayerScore(0)
      this.playerScoreInputValue = ''
      this.playersNumberOfDartArrowsTrackingArray[this.matchSetRounds.getNumberOfRoundsPlayed()] = this.getNumberOfDartArrowsThrown()
      this.playersNumberOfDartArrowsTrackingArray = this.playersNumberOfDartArrowsTrackingArray.slice(0, this.matchSetRounds.getNumberOfRoundsPlayed() + 1)
      this.roundsModeData.status = (this.roundsModeData.status >= 2 ? 1 : this.roundsModeData.status)
      this.retrieveAndShowScore()
      this.updateInputLabelWhoseTurnItIs()
    }
  }

  public resetMatchSetRounds () : void {
    // console.log('[resetMatchSetRounds] DONE.')

    if (this.matchSetRounds !== undefined) {
      this.matchSetRounds.clearAllRounds()
      this.retrieveAndShowScore()
      this.updateInputLabelWhoseTurnItIs()
      this.roundsModeData.status = 0
      this.playersNumberOfDartArrowsTrackingArray = []
      this.dart43Flag = false
    }

    this.resetWarningModal = false
  }

  public retrieveAndShowScore () : number[][] {
    // console.log('[retrieveAndShowScore] DONE.')

    if (this.matchSetRounds !== undefined) {
      // console.log('Score array retrieved = ' + JSON.stringify(this.matchSetRounds.getScoresOfAllRoundsPlayed()))
      // console.log('Number of rounds played by each player = ' + JSON.stringify(this.matchSetRounds.getNumberOfRoundsPlayedByPlayers()))

      return this.matchSetRounds.getScoresOfAllRoundsPlayed()
    }

    return [[]]
  }

  // Get the number of rounds played by a certain player.
  public getNumberOfRoundsPlayedByPlayer (playerVal = true) : number {
    if (this.matchSetRounds !== undefined) {
      return this.matchSetRounds.getNumberOfRoundsPlayedByPlayers()[(playerVal ? 1 : 0)]
    }

    return -1
  }

  public getRealRoundsPlayedByPlayer () : any {
    if (this.matchSetRounds !== undefined) {
      return this.matchSetRounds.getNumberOfRoundsPlayedByPlayers()
    }
  }

  // Get the number of arrows thrown by each player.
  public getNumberOfDartArrowsThrown () : number[] {
    const retVal = [0, 0]

    if (this.matchSetRounds !== undefined) {
      const numberOfArrowsPerRound = this.matchSetRounds.getClassOptions().arrowsPerRound
      const numberOfRealRoundsPlayed = this.matchSetRounds.getNumberOfRoundsPlayedByPlayers()
      const extraInfo = this.matchSetRounds.getExtraInfoAboutTheRounds()

      if (extraInfo.isRoundCompleted && extraInfo.winningPlayer !== null && extraInfo.numberOfClosingArrows !== null) {
        return [((numberOfRealRoundsPlayed[0] - (extraInfo.winningPlayer === false ? 1 : 0)) * numberOfArrowsPerRound + (extraInfo.winningPlayer === false ? extraInfo.numberOfClosingArrows : 0)), ((numberOfRealRoundsPlayed[1] - (extraInfo.winningPlayer === true ? 1 : 0)) * numberOfArrowsPerRound + (extraInfo.winningPlayer === true ? extraInfo.numberOfClosingArrows : 0))]
      } else {
        return [(numberOfRealRoundsPlayed[0] * numberOfArrowsPerRound), (numberOfRealRoundsPlayed[1] * numberOfArrowsPerRound)]
      }
    }

    return retVal
  }

  // Get the number of arrows thrown draft
  public getNumberOfDartArrowsThrownDraft (arrayOfRealRound: any) : number[] {
    const retVal = [0, 0]

    if (this.matchSetRounds !== undefined) {
      const numberOfArrowsPerRound = this.matchSetRounds.getClassOptions().arrowsPerRound
      const numberOfRealRoundsPlayed = arrayOfRealRound
      const extraInfo = this.matchSetRounds.getExtraInfoAboutTheRounds()

      if (extraInfo.isRoundCompleted && extraInfo.winningPlayer !== null && extraInfo.numberOfClosingArrows !== null) {
        return [((numberOfRealRoundsPlayed[0] - (extraInfo.winningPlayer === false ? 1 : 0)) * numberOfArrowsPerRound + (extraInfo.winningPlayer === false ? extraInfo.numberOfClosingArrows : 0)), ((numberOfRealRoundsPlayed[1] - (extraInfo.winningPlayer === true ? 1 : 0)) * numberOfArrowsPerRound + (extraInfo.winningPlayer === true ? extraInfo.numberOfClosingArrows : 0))]
      } else {
        return [(numberOfRealRoundsPlayed[0] * numberOfArrowsPerRound), (numberOfRealRoundsPlayed[1] * numberOfArrowsPerRound)]
      }
    }

    return retVal
  }

  public getMaxValueOfNumberOfDartArrowsThrown (index: number) : number {
    let retVal = 0

    if (this.playersNumberOfDartArrowsTrackingArray[index] !== undefined) {
      retVal = Math.max((this.playersNumberOfDartArrowsTrackingArray[index][1] === undefined ? 0 : this.playersNumberOfDartArrowsTrackingArray[index][1]), (this.playersNumberOfDartArrowsTrackingArray[index][0] === undefined ? 0 : this.playersNumberOfDartArrowsTrackingArray[index][0]))
    }

    return retVal
  }

  // Get the base information about the winning player (if there is one determined).
  public getWinnerText () : string {
    return (this.winningPlayer !== null ? this.textPlayerWon[(this.winningPlayer ? 1 : 0)] : 'Ikke afgjort')
  }

  public getPlayersPointsRemaining () : number[] {
    let retVal = [0, 0]

    if (this.matchSetRounds !== undefined) {
      retVal = this.matchSetRounds.getPlayersPointsRemaining()
    }

    return retVal
  }

  public getPlayersPointsRemainingExceptCurrentPlayer (index: number) : number[] {
    let retVal = [0, 0]

    if (this.matchSetRounds !== undefined) {
      retVal = this.matchSetRounds.getPlayersPointsRemainingExceptCurrentPlayer(index)
    }

    return retVal
  }

  public getPlayersAverageScore () : number[] {
    let retVal = [0, 0]

    if (this.matchSetRounds !== undefined) {
      retVal = this.matchSetRounds.getAverageScoreOfRoundsPlayed()

      if (retVal === undefined || retVal.length < 1) {
        retVal = [0, 0]
      }
    }

    return retVal
  }

  public updateInputLabelWhoseTurnItIs () : void {
    if (this.matchSetRounds !== undefined) {
      const extraInfo = this.matchSetRounds.getExtraInfoAboutTheRounds()
      this.roundsModeData.currentPlayerWhoseTurnItIs = extraInfo.currentPlayerTurn
      this.labelCurrentPlayerWhoseTurnItIs = this.labelPlayerTeamText[(this.roundsModeData.currentPlayerWhoseTurnItIs ? 1 : 0)]
      this.finalMatchSetRoundsClosingDartsModal = (this.finalMatchSetRoundsClosingDartsModal === false && extraInfo.isRoundCompleted)
      this.winningPlayer = extraInfo.winningPlayer
      // console.log('[updateInputLabelWhoseTurnItIs] winningPlayer = ' + (extraInfo.winningPlayer !== null ? extraInfo.winningPlayer.toString() : '-'))

      if (extraInfo.winningPlayer !== null) {
        // Check for possible number of dart arrows used in the closing round by the winning player and update the selection options in the UI accordingly.
        this.checkForPossibleClosingNumberOfDartArrowsThrown()
      }

      this.inhibitNextTurn = extraInfo.isRoundCompleted
    }
  }

  public checkForPossibleClosingNumberOfDartArrowsThrown () : void {
    if (!this.roundsModeData.readOnlyMode) {
      if (this.matchSetRounds !== undefined) {
        const numberOfArrowsPerRound = this.matchSetRounds.getClassOptions().arrowsPerRound
        const numberOfDartArrowsPossible = this.matchSetRounds.checkForPossibleClosingNumberOfDartArrowsThrown()
        this.closingNumberOfDartArrowsPossibleOptions = []

        if (numberOfDartArrowsPossible.length > 0) {
          for (let arrIndex = 1; arrIndex <= numberOfArrowsPerRound; arrIndex++) {
            const valueNotFoundFlag = (numberOfDartArrowsPossible.find(x => x === arrIndex) === undefined)
            this.closingNumberOfDartArrowsPossibleOptions.push({ text: (arrIndex >= 2 ? arrIndex.toString() + ' pile' : arrIndex.toString() + ' pil'), value: arrIndex.toString(), disabled: valueNotFoundFlag })
            if (!valueNotFoundFlag) {
              this.closingNumberOfDartArrowsPossibleValue = arrIndex.toString()
            }
          }
        } else {
          this.closingNumberOfDartArrowsPossibleValue = '0'
        }
      }
    }
  }

  public saveFinalMatchSetRoundsClosingDartArrows () : void {
    // console.log('[saveFinalMatchSetRoundsClosingDartArrows] closingNumberOfDartArrowsPossibleValue = ' + JSON.stringify(this.closingNumberOfDartArrowsPossibleValue))
    this.roundsModeData.status = 2

    if (this.matchSetRounds !== undefined) {
      this.matchSetRounds.setNumberOfClosingArrowsUsed(Number(this.closingNumberOfDartArrowsPossibleValue))
      this.playersNumberOfDartArrowsTrackingArray[this.matchSetRounds.getNumberOfRoundsPlayed()] = this.getNumberOfDartArrowsThrown()
    }

    this.finalMatchSetRoundsClosingDartsModal = false
  }

  public cancelFinalMatchSetRoundsClosingDartsModal () {
    this.undoToPreviousTurn()
    this.finalMatchSetRoundsClosingDartsModal = false
  }

  public approveMatchSetResultsAsFinal () : void {
    // console.log('[approveMatchSetResultsAsFinal] DONE.')
    // Clear the match data from draft
    this.updateDraftScoreOfMatch({ clear: true })
    this.isRoundScoreUpdate = false
    const theWinnerIs = (this.winningPlayer !== null && this.winningPlayer ? 1 : 0)

    if (!this.roundsModeData.readOnlyMode && this.winningPlayer !== null) {
      this.roundsModeData.status = 3

      // Adjust player match set score according to the approved match set result, and if necessary advance to the round of the next match set.
      this.playersTotalMatchSetScoreForTheMatch[theWinnerIs]++
      this.tempPlayerStartingFirstRound = this.roundsModeData.playerStartingFirstRound

      if (this.playersTotalMatchSetScoreForTheMatch[theWinnerIs] < this.roundsModeData.submatchWinAtNumberOfWonSets) {
        this.changeAdvMatchSetRoundsModeState(true, this.roundsModeData.status)
        // console.log('[approveMatchSetResultsAsFinal] winning player = ' + theWinnerIs.toString())
        this.resetMatchSetRounds()
        this.roundsModeData.playerStartingFirstRound = !this.tempPlayerStartingFirstRound

        this.roundsModeData.matchSet[theWinnerIs]++
        // console.log('[approveMatchSetResultsAsFinal] matchset = ' + this.roundsModeData.matchSet[0].toString() + ' / ' + this.roundsModeData.matchSet[1].toString())

        if (this.matchSetRounds !== undefined) {
          // console.log('[approveMatchSetResultsAsFinal] Updating the match set values and first round starting player in the backend code.')
          this.matchSetRounds.setMatchSet(this.roundsModeData.matchSet)
          this.matchSetRounds.setPlayerStartingFirstRound(this.roundsModeData.playerStartingFirstRound)
          this.updateInputLabelWhoseTurnItIs()
        }
      } else {
        this.changeAdvMatchSetRoundsModeState(false, this.roundsModeData.status)
        this.resetMatchSetRounds()
        // console.log('[approveMatchSetResultsAsFinal] FINE')
      }
    }
  }

  public resumeMatchSet () : void {
    if (this.matchSetRounds !== undefined) {
      this.roundsModeData.playerStartingFirstRound = (this.resumeMatchSetValue === '1')
      this.matchSetRounds.setPlayerStartingFirstRound(this.roundsModeData.playerStartingFirstRound)
      this.updateInputLabelWhoseTurnItIs()
      this.resumeMatchSetModal = false
    }
  }

  public async getDraftScoreOfMatch (payload: string) : Promise<void> {
    await Draft.DraftMatchScoreDataService.getDraftMatchScore(payload)
      .then((response) => {
        console.log('RESPONSE...', response)
        this.setDraftScoreOfMatch(response?.data?.scores, response?.data?.realRoundsPlayed)
      })
      .catch((error) => {
        console.log('ERROR...', error)
      })
  }

  public setDraftScoreOfMatch (data: any, realRound: any) : void {
    if (data.length > 0 && this.matchSetRounds !== undefined && realRound) {
      this.isRoundScoreUpdate = true
      const roundsScoresArr = []

      // close who Start the match selection model
      this.resumeMatchSetModal = false
      for (let index = 0; index < data.length; index++) {
        const roundsScoresSubArr = [data[index].awayPlayerScore, data[index].homePlayerScore]
        roundsScoresArr.push(roundsScoresSubArr)
      }
      console.log('REAL_ROUND_RESPONSE', realRound)

      // Set realround played
      this.matchSetRounds.setNumberOfRoundsPlayedByPlayers(realRound)

      // Set the current round
      this.matchSetRounds.setNumberOfRoundsPlayed(Math.min(...realRound))

      // If the realround's value is same then set next round with [0,0]
      if (realRound[0] === Math.min(...realRound) && realRound[1] === Math.min(...realRound)) {
        roundsScoresArr.push([0, 0])
      }

      // Set the player turn
      if (this.roundsModeData.playerStartingFirstRound) {
        if (realRound[1] > realRound[0]) {
          this.roundsModeData.currentPlayerWhoseTurnItIs = false
          this.matchSetRounds.setCurrentPlayerTurn(false)
        }
      } else {
        if (realRound[1] < realRound[0]) {
          this.roundsModeData.currentPlayerWhoseTurnItIs = true
          this.matchSetRounds.setCurrentPlayerTurn(true)
        }
      }

      // Set the Draft numbers
      for (let index = 0; index < roundsScoresArr.length; index++) {
        this.playersNumberOfDartArrowsTrackingArray[index] = this.getNumberOfDartArrowsThrownDraft(this.roundsModeData.playerStartingFirstRound ? [index, index + 1] : [index + 1, index])
      }
      this.matchSetRounds.setScoresOfAllRoundsPlayed(roundsScoresArr)
      console.log('roundsScoresArr', roundsScoresArr)
    }
  }

  public async updateDraftScoreOfMatch (payload: any) : Promise<void> {
    if (this.selectedMatchId !== undefined) {
      await Draft.DraftMatchScoreDataService.updateDraftMatchScore(String(this.selectedMatchId), payload)
        .then((response) => {
          console.log('Update_RESPONSE...', response)
        })
        .catch((error) => {
          console.log('Update_ERROR...', error)
        })
    }
  }

  public setPlayersNameFormate (nameStr: any) : string {
    const nameArr = nameStr.split(',')
    if (nameArr.length > 1) {
      const nameSubArr0 = nameArr[0].split(' ')
      const nameSubArr1 = nameArr[1].split(' ')
      const firstName0 = nameSubArr0[0].split('')[0]
      const firstName1 = nameSubArr1[0].split('')[0]
      nameSubArr0.shift()
      nameSubArr1.shift()
      return firstName0 + ' ' + nameSubArr0.join(' ') + '\n' + firstName1 + ' ' + nameSubArr1.join(' ')
    } else {
      const nameSubArr = nameArr[0].split(' ')
      const firstName = nameSubArr[0].split('')[0]
      nameSubArr.shift()
      // return nameSubArr.length > 0 ? firstName + ' ' + nameSubArr[0] : nameStr
      return nameSubArr.length > 0 ? firstName + ' ' + nameSubArr.join(' ') : nameStr
    }
  }

  // popup when click at Dart 43, should automatic show after 14 rounds
  @Watch('matchSetRounds.currentRound')
  onCurrentRoundChange () : void {
    if (this.matchSetRounds?.getNumberOfRoundsPlayed() === 14) {
      // exept for Junior Category tournament
      if (defaultJuniorTournamentSpringId !== Number(this.tournamentCategoryId) && defaultJuniorTournamentFallId !== Number(this.tournamentCategoryId)) {
        this.doDart43()
      }
    }
  }

  @Watch('matchSetRounds.roundScore', { deep: true })
  onRoundScoreUpdate () : void {
    if (this.route.name === 'TournamentMatch') {
      const remainingScore = this.getPlayersPointsRemaining()
      const totalWins = this.playersTotalMatchSetScoreForTheMatch
      const score = this.retrieveAndShowScore()
      const scoreArr = []
      const roundPlayed = this.getRealRoundsPlayedByPlayer()
      const startedBy = (this.matchTeamPlayersNames !== undefined && this.roundsModeData.playerStartingFirstRound) ? this.matchTeamPlayersNames[1] : this.matchTeamPlayersNames[0]
      console.log('roundPlayed', Math.max(...roundPlayed))

      // Check for max win number
      const isMatchSetEqualToTheWonSets = (Math.max(...this.roundsModeData.matchSet) === this.roundsModeData.submatchWinAtNumberOfWonSets)
      console.log('isMatchSetLessthenWonSets.....', isMatchSetEqualToTheWonSets)
      // if (Math.max(...roundPlayed) > 0) {
      if (this.isRoundScoreUpdate && !isMatchSetEqualToTheWonSets) {
        for (let index = 0; index < score.length; index++) {
          const obj = {
            darts: this.getMaxValueOfNumberOfDartArrowsThrown(index),
            position: index + 1,
            homePlayerScore: score[index][1],
            awayPlayerScore: score[index][0]
          }
          scoreArr.push(obj)
        }
        if ((scoreArr.length > 1 && scoreArr[scoreArr.length - 1].darts === scoreArr[scoreArr.length - 2].darts) || scoreArr.length > Math.max(...roundPlayed)) {
          scoreArr.pop()
        }
        const payloadOfUpdateScore = {
          status: true,
          realRoundsPlayed: roundPlayed,
          remainingHomePlayerScore: remainingScore[1],
          remainingAwayPlayerScore: remainingScore[0],
          startedBy: startedBy,
          homeTotalwins: totalWins[1],
          awayTotalwins: totalWins[0],
          scores: scoreArr,
          awayTeamResult: this.awayTeamResult,
          homeTeamResult: this.homeTeamResult
        }
        console.log('payloadOfUpdateScore', payloadOfUpdateScore)
        this.updateDraftScoreOfMatch(payloadOfUpdateScore)
      }

      // Set to true after mounted
      this.isRoundScoreUpdate = true
    }
  }

  async mounted () : Promise<void> {
    // WIP
    this.roundsModeData.readOnlyMode = (this.readOnlyFlag === undefined ? false : this.readOnlyFlag)
    this.roundsModeData.playerStartingFirstRound = (this.playerToStartFirstRound === undefined ? true : this.playerToStartFirstRound)
    this.roundsModeData.matchIndexNumber = (this.matchIndexNumber === undefined ? 0 : this.matchIndexNumber)
    this.roundsModeData.matchSet[0] = (this.matchSet === undefined || this.matchSet.length < 2 ? 0 : this.matchSet[0])
    this.roundsModeData.matchSet[1] = (this.matchSet === undefined || this.matchSet.length < 2 ? 0 : this.matchSet[1])
    this.playersTotalMatchSetScoreForTheMatch[0] = this.roundsModeData.matchSet[0]
    this.playersTotalMatchSetScoreForTheMatch[1] = this.roundsModeData.matchSet[1]
    this.roundsModeData.submatchWinAtNumberOfWonSets = (this.submatchWinAtNumberOfWonSets === undefined ? 3 : this.submatchWinAtNumberOfWonSets)
    // console.log('[mounted()] Player starting = ' + this.roundsModeData.playerStartingFirstRound.toString())
    this.playersNumberOfDartArrowsTrackingArray = [[0, 0]]

    // Handle player team names, they have been supplied in the suitable property.
    if (this.playerTeamNames !== undefined && this.playerTeamNames.length >= 2) {
      this.scoreBoardHeadText = [this.playerTeamNames[0], this.playerTeamNames[1]]
      this.labelPlayerTeamText = [this.playerTeamNames[0], this.playerTeamNames[1]]
      this.textPlayerWon = ['Spilleren fra holdet ' + this.playerTeamNames[0] + ' har vundet', 'Spilleren fra holdet ' + this.playerTeamNames[1] + ' har vundet']
      this.playerWhoWonOptions = [{ text: 'Udeholdspilleren fra ' + this.playerTeamNames[0], value: '0' }, { text: 'Hjemmeholdspilleren fra ' + this.playerTeamNames[1], value: '1' }]
      this.resumeMatchSetOptions = [{ text: 'Udeholdspilleren fra ' + this.playerTeamNames[0], value: '0', disabled: false }, { text: 'Hjemmeholdspilleren fra ' + this.playerTeamNames[1], value: '1', disabled: false }]
    }

    // Handle player names
    if (this.matchTeamPlayersNames !== undefined && this.matchTeamPlayersNames.length >= 0 && this.matchTeamSecondPlayersNames !== undefined && this.matchTeamSecondPlayersNames.length >= 0 && this.matchIsDouble !== undefined) {
      const homePlayerNames = this.matchIsDouble ? this.matchTeamPlayersNames[1] + ',' + this.matchTeamSecondPlayersNames[1] : this.matchTeamPlayersNames[1]
      const awayPlayerNames = this.matchIsDouble ? this.matchTeamPlayersNames[0] + ',' + this.matchTeamSecondPlayersNames[0] : this.matchTeamPlayersNames[0]
      this.scoreBoardHeadText = [awayPlayerNames, homePlayerNames]
      this.labelPlayerTeamText = [awayPlayerNames, homePlayerNames]
    }
    this.matchSetRounds = new Tournament501MatchSetRounds(Object.assign({}, Tournament501MatchSetRounds.defaultOptions, { readOnlyMode: this.roundsModeData.readOnlyMode, playerStartingFirstRound: this.roundsModeData.playerStartingFirstRound, matchIndexNumber: this.roundsModeData.matchIndexNumber, matchSet: this.roundsModeData.matchSet }))
    // console.log('[mounted] ' + (this.matchSetRounds !== undefined ? JSON.stringify(this.matchSetRounds.getClassOptions()) : 'Class is not defined (yet).'))
    this.updateInputLabelWhoseTurnItIs()

    if (this.roundsModeData.matchSet[0] > 0 || this.roundsModeData.matchSet[1] > 0) {
      this.resumeMatchSetValue = (this.roundsModeData.playerStartingFirstRound ? '1' : '0')
      this.resumeMatchSetModal = true
    }

    // Get the draft score
    if (this.matchTeamPlayersNames !== undefined && this.matchTeamPlayersNames.length >= 0 && this.selectedMatchId !== undefined && this.matchId !== undefined && this.playerTeamNames !== undefined && this.playerTeamNames.length >= 0 && this.matchTeamSecondPlayersNames !== undefined && this.matchTeamSecondPlayersNames.length > 0 && this.matchIsDouble !== undefined) {
      const homePlayerNames = this.matchIsDouble ? this.matchTeamPlayersNames[1] + ',' + this.matchTeamSecondPlayersNames[1] : this.matchTeamPlayersNames[1]
      const awayPlayerNames = this.matchIsDouble ? this.matchTeamPlayersNames[0] + ',' + this.matchTeamSecondPlayersNames[0] : this.matchTeamPlayersNames[0]
      const payload = `matchSetId=${this.selectedMatchId}&homePlayerName=${homePlayerNames}&awayPlayerName=${awayPlayerNames}&matchId=${this.matchId}&homeTeamName=${this.playerTeamNames[1]}&awayTeamName=${this.playerTeamNames[0]}`
      this.getDraftScoreOfMatch(payload)
    }
  }
}
